@import '../styles/breakpoints.scss';

.top {
  padding-top: 40px;

  @include lg {
    padding-top: 36px;
  }

  @include md {
    padding-top: 32px;
  }

  @include smDown {
    padding-top: 24px;
  }
}

.bottom {
  padding-bottom: 40px;

  @include lg {
    padding-bottom: 36px;
  }

  @include md {
    padding-bottom: 32px;
  }

  @include smDown {
    padding-bottom: 24px;
  }
}

.left {
  padding-left: 40px;

  @include lg {
    padding-left: 36px;
  }

  @include md {
    padding-left: 32px;
  }

  @include smDown {
    padding-left: 16px;
  }
}

.right {
  padding-right: 40px;

  @include lg {
    padding-right: 36px;
  }

  @include md {
    padding-right: 32px;
  }

  @include smDown {
    padding-right: 16px;
  }
}
