$xs-max: 419.9px;
$sm-min: 420px;
$sm-max: 719.9px;
$md-min: 720px;
$md-max: 1365.9px;
$lg-min: 1366px;
$lg-max: 1919.9px;
$xl-min: 1920px;

@mixin xs() {
  @media (max-width: $xs-max) {
    @content;
  }
}

@mixin sm() {
  @media (min-width: $sm-min) and (max-width: $sm-max) {
    @content;
  }
}

@mixin md() {
  @media (min-width: $md-min) and (max-width: $md-max) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: $lg-min) and (max-width: $lg-max) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: $xl-min) {
    @content;
  }
}

@mixin smUp() {
  @media (min-width: $sm-min) {
    @content;
  }
}

@mixin mdUp() {
  @media (min-width: $md-min) {
    @content;
  }
}

@mixin lgUp() {
  @media (min-width: $lg-min) {
    @content;
  }
}

@mixin xlUp() {
  @media (min-width: $xl-min) {
    @content;
  }
}

@mixin xsDown() {
  @media (max-width: $xs-max) {
    @content;
  }
}

@mixin smDown() {
  @media (max-width: $sm-max) {
    @content;
  }
}

@mixin mdDown() {
  @media (max-width: $md-max) {
    @content;
  }
}

@mixin lgDown() {
  @media (max-width: $lg-max) {
    @content;
  }
}

@mixin xlDown() {
  @media (max-width: $xl-max) {
    @content;
  }
}
