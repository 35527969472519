@import '../styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  color: var(--text-color-secondary);
}

.detailIcon {
  margin-right: 8px;
}

.successIcon {
  color: var(--text-color-positive);
}

.errorIcon {
  color: var(--text-color-danger);
}

.labelText {
  margin-top: 24px;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-medium);
  color: var(--text-color-primary);
  text-align: center;
}

.successButton {
  margin-top: 24px;

  &:global(.ant-btn) {
    color: var(--text-color-positive);
  }
}
