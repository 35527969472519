.helpContainer {
  position: fixed;
  width: 350px;
  height: 140px;
  z-index: 2;
  bottom: 0;
  right: 0;

  .closeButton {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .popupContainer {
    margin: 20px;
    padding: 10px;
    box-shadow: var(--box-shadow-deep);
    background-color: var(--white);
    border-radius: 8px;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    border: 1px solid grey;

    .bigIcon {
      font-size: 70px;
      margin-right: 10px;
      color: var(--teal);
    }

    .textContainer {
      .title {
        font-size: var(--font-size-l);
        font-weight: var(--font-weight-bolder);
      }
      .subTitle {
        font-size: var(--font-size-s);
      }
      .link {
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-bold);
      }
    }
  }
}
