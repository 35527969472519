:global(.ant-btn) {
  &.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important; // antd uses important
    color: inherit;

    &.inline {
      display: inline-flex;
    }
  }

  &.tiny {
    &:global(.ant-btn) {
      width: 24px;
      height: 24px;

      .icon {
        width: 12px;
        height: 12px;
      }
    }

    &:global(.ant-btn-circle) {
      min-width: 24px;
    }
  }

  &.small {
    &:global(.ant-btn) {
      width: 32px;
      height: 32px;

      .icon {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.medium {
    &:global(.ant-btn) {
      width: 40px;
      height: 40px;

      .icon {
        width: 22px;
        height: 22px;
      }
    }
  }

  &.large {
    &:global(.ant-btn) {
      width: 52px;
      height: 52px;

      .icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .colorDefault {
    color: var(--black);

    &.highlighted {
      color: var(--blue-ribbon);
    }
  }

  .colorPrimary {
    color: var(--blue-ribbon);
  }

  .colorGrey {
    color: var(--granite-gray);

    &.highlighted {
      color: var(--blue-ribbon);
    }
  }

  .colorLightGrey {
    color: var(--gray);

    &.highlighted {
      color: var(--blue-ribbon);
    }
  }

  .colorWhite {
    color: var(--white);
  }

  .colorDanger {
    color: var(--error);
  }

  .colorInherit {
    color: inherit;

    &.highlighted {
      color: var(--blue-ribbon);
    }
  }

  &.button:disabled {
    opacity: 0.6;
  }
}
