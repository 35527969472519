:global(.ant-btn) {
  &.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-medium);
    letter-spacing: -0.32px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 8px 18px !important; // cause antd used important
    height: 44px;

    &.alignStart {
      justify-content: flex-start;
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    .iconLeft {
      margin-right: 12px;
      margin-left: -12px;
    }

    .iconRight {
      margin-left: 12px;
      margin-right: -12px;
    }

    &:global(.ant-btn-sm) {
      font-size: var(--font-size-m);
      line-height: var(--font-size-m);
      padding: 4px !important; // cause antd used important
      height: 32px;
      text-transform: capitalize;

      .icon {
        width: 16px;
        height: 16px;
      }

      .iconLeft {
        margin-right: 8px;
        padding-left: 8px;
      }

      .iconRight {
        margin-left: 8px;
        padding-right: 8px;
      }
    }

    &:global(.ant-btn-lg) {
      font-size: var(--font-size-l);
      line-height: 18px;
      padding: 8px 18px !important; // cause antd used important
      height: 56px;

      .icon {
        width: 24px;
        height: 24px;
      }

      .iconLeft {
        margin-right: 12px;
        padding-left: 12px;
      }

      .iconRight {
        margin-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

:global(.ant-btn) {
  &.button.tiny {
    &:global(.ant-btn-sm) {
      padding: 2px !important; // cause antd used important
      height: 24px;
    }
  }
}

:global(.ant-btn.ant-btn-sm).antBtnSmWithIconPadding {
  padding-right: 8px !important; // cause antd used important
}
:global(.ant-btn.ant-btn-sm).antBtnSmWithIconRightPadding {
  padding-left: 8px !important; // cause antd used important
}
:global(.ant-btn.ant-btn-sm).antBtnWithIconPadding {
  padding-right: 12px !important; // cause antd used important
}
:global(.ant-btn.ant-btn-sm).antBtnWithIconRightPadding {
  padding-left: 12px !important; // cause antd used important
}
